@font-face {
  font-family: 'Sora';
  src: url('/assets/fonts/Sora-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('/assets/fonts/Sora-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('/assets/fonts/Sora-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('/fonts/Sora-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('/fonts/Sora-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('/fonts/Sora-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('/fonts/Sora-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('/fonts/Sora-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'Sora', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4; 
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6; 
}
@v4Token: [object Object];@BASE_PATH: 'https://testnet.sharemanage.com';