.se-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 0 24px;
  }

  &-system {
    display: flex;
    flex-direction: row;
    gap: 12px;

    &-logo {
      width: 32px;
      height: 32px;
      background-image: url('@{BASE_PATH}/logo192.png');
      background-repeat: no-repeat;
      background-size: contain;
      margin: auto 0;
    }

    &-name {
      color: rgba(255, 255, 255, 0.75);
      font-size: 20px;
      font-weight: 500;
    }
  }

  &-admin {
    display: flex;
    flex-direction: row;

    &-menu {
      height: 36px;
      cursor: pointer;
    }

    &-content {
      display: flex;
      flex-direction: row;
      background-color: rgba(255, 255, 255, 0.15);
      padding: 0 18px;
      margin: auto 0;
      border-radius: 8px;
      opacity: 0.75;
      transition: opacity 400ms ease-out;

      &:hover {
        opacity: 1;
      }

      &-name {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 36px;
        padding-right: 12px;
        margin: auto 0;
      }

      &-icon.anticon {
        display: flex;
        color: #fff;
        font-size: 20px;
        margin: auto 0;
      }
    }
  }
}
@v4Token: [object Object];@BASE_PATH: 'https://testnet.sharemanage.com';