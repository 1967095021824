@import '@/styles/variables.less';

.se-layout {

  &-body.ant-layout {
    background-color: @darkBackground;
  }

  &-content.ant-layout {
    width: 100%;
    height: calc(100vh - @headerHeight);
    background-color: @contentBackground;
    padding: 8px 8px 0;
    margin-top: @headerHeight;
    margin-left: @sidebarWidth;
    border-radius: @borderRadius 0 0 0;
    transition: 200ms ease;
    overflow: hidden;

    &.expanded {
      margin-left: @sidebarCollapsedWidth;
    }

    &-wrapper {
      width: 100%;
      height: calc(100vh - @headerHeight - 8px);
      overflow: hidden;
    }
  }
}
@v4Token: [object Object];@BASE_PATH: 'https://testnet.sharemanage.com';