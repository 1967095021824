.bg_color {
  background-color: #f0f2f5;
}

.bg_image {
  position: absolute;
  top: 50%;
  width: 100vw;
  transform: translateY(-50%);
  height: 70%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/image/bg.png");
}
.center {
  text-align: center;
  padding: 50px;
}
.title {
  font-weight: bold;
  font-size: 40px;
}
.small_title {
  font-size: 14px;
}
.form_container {
  margin: 0 auto;
  width: 360px;
}
.login {
  font-weight: 500;
  font-size: 24px;
  color: #1890ff;
  padding-bottom: 30px;
}

.icon {
  color: #1890ff;
  padding: 8px;
}
.form {
  width: 360px;
  padding-top: 10px
}
.footer {
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 20px;
}

@v4Token: [object Object];@BASE_PATH: 'https://testnet.sharemanage.com';