@import '@/styles/variables.less';
@import '@/styles/theme.less';
@import '@/styles/overwrite.less';


.main {
  width: 100vw;
  height: 100vh;
  min-width: 1028px;
  min-height: 460px;
}

@v4Token: [object Object];@BASE_PATH: 'https://testnet.sharemanage.com';