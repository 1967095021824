&.ant-form-item {
  .ant-form-item-label {
    padding-bottom: 0;

    label {
      height: 28px;
      font-weight: 600;
      color: #585858;
    }
  }
}