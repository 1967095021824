@import '@/styles/variables.less';

.se-sidebar {
  &.ant-layout-sider {
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: @headerHeight;
    z-index: 998;
  }

  .ant-menu.ant-menu-root {
    &.ant-menu-inline {
      .ant-menu-item {
        margin: 6px;
        border-radius: @borderRadius;
        width: calc(100% - 12px);
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item {
        margin: 8px auto;
        border-radius: @borderRadius;
        width: calc(100% - 24px);
        padding: 0 calc(50% - 20px);
      }
    }
  }
}

@v4Token: [object Object];@BASE_PATH: 'https://testnet.sharemanage.com';